import * as React        from 'react';
import Button            from '@mui/material/Button';
import TextField         from '@mui/material/TextField';
import Dialog            from '@mui/material/Dialog';
import DialogActions     from '@mui/material/DialogActions';
import DialogContent     from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle       from '@mui/material/DialogTitle';
import { Trans }         from 'react-i18next'
import { useApi }        from '../utils/api'
import { useSnackbar }   from 'notistack'

export default function CopyZoneDialog({ t, zone, onZoneDuplicated }) {

  const api = useApi()
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copy = async (newName, keyword, pattern) => {
    try {
      const newZone = await api.zone.save({ ...zone, id: undefined, name: newName, keyword: keyword, pattern: '', active: false })
      enqueueSnackbar(t('Zone duplicated'), {
        variant: 'success'
      })
      return newZone
    } catch (e) {
      enqueueSnackbar(t('Failed to duplicate zone'), {
        variant: 'error'
      })
    }
  }

  return (
    <React.Fragment>
      <Button size={'small'} variant={'link'} disableElevation onClick={handleClickOpen}>
        {t('zone-duplicate')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault()
            const formData = new FormData(event.currentTarget)
            const formJson = Object.fromEntries(formData.entries())
            copy(formJson.name, formJson.keyword).then(newZone => {
              if (!!onZoneDuplicated) {
                onZoneDuplicated(newZone)
              }
            }).catch(() => { /* nothing to do */})
            handleClose();
          },
        }}
      >
        <DialogTitle>{t('zone-duplication')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans i18nKey={'zone-copy-desc'} values={{ name: zone.name }} />
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label={t('zone-new-name')}
            fullWidth
            defaultValue={zone.name}
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="keyword"
            name="keyword"
            label={t('Keyword')}
            fullWidth
            defaultValue={zone.keyword}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button type="submit">{t('Save')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}