import React, { useContext }                        from 'react'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'
import {DashboardLayout, CustomerLayout }          from './ui/layouts/dashboard'
import CitiesPage                                   from './city/CitiesPage'
import CityPage              from './city/CityPage'
import ZonePage              from './city/ZonePage'
import HomePage              from './home/HomePage'
import UserPage              from './user/UserPage'
import UsersPage             from './user/UsersPage'
import { ParkingAppContext } from './App'
import SignInPage            from './user/SignInPage'
import TicketsPage           from './ticket/TicketsPage'
import AppAccessPage         from './appAccess/AppAccessPage'
import AppAccessesPage       from './appAccess/AppAccessesPage'
import SearchPage            from './search/SearchPage'
import MessagesPage          from './message/MessagesPage'
import MessagePage           from './message/MessagePage'
import TicketPage            from './ticket/TicketPage'
import CustomerSignInPage    from './customer/CustomerSignInPage'
import CustomerTicketsPage   from './customer/CustomerTicketsPage'
import TicketStatisticsPage  from './ticket/TicketStatisticsPage'
import MessageStatisticsPage from './message/MessageStatisticsPage'
// layouts

// ----------------------------------------------------------------------

const ProtectedRoute = ({ allowedUserTypes, children }) => {

  const location = useLocation()
  const { user } = useContext(ParkingAppContext)
  if (user === null) {
    return <Navigate to="/sign-in" state={{ from: location }}/>
  }

  if (!!allowedUserTypes && allowedUserTypes.indexOf(user.type) === -1){
    return <Navigate to={'/home'} />
  }

  return (<React.Fragment>{children}</React.Fragment>)
}

const AdminProtectedRoute = ({ children }) => {
  return <ProtectedRoute allowedUserTypes={['admin']}>
    {children}
  </ProtectedRoute>
}

const OwnerProtectedRoute = ({ children }) => {
  return <ProtectedRoute allowedUserTypes={['admin', 'owner']}>
    {children}
  </ProtectedRoute>
}

const CustomerProtectedRoute = ({ children }) => {

  const location = useLocation()
  const { phone } = useContext(ParkingAppContext)
  if (phone === null) {
    return <Navigate to="/customer/sign-in" state={{ from: location }}/>
  }

  return (<React.Fragment>{children}</React.Fragment>)
}

const OnlyPublicRoute = ({ children }) => {

  const location = useLocation()
  const { user, phone } = useContext(ParkingAppContext)
  if (user !== null || phone !== null) {
    return <Navigate to={'/'} state={{ from: location }}/>
  }

  return (<React.Fragment>{children}</React.Fragment>)
}
const IndexRoute = () => {

  const location = useLocation()
  const { user, phone } = useContext(ParkingAppContext)
  if (user !== null) {
    return <Navigate to={'/home'} state={{ from: location }}/>
  }
  if (phone !== null) {
    return <Navigate to={'/customer/tickets'} state={{ from: location }}/>
  }

  return <Navigate to={'/customer/sign-in'} state={{ from: location }}/>
}

export default function Router () {

  return useRoutes([
    {
      path: '/',
      element: <ProtectedRoute><DashboardLayout/></ProtectedRoute>,
      children: [
        { path: 'cities/:cityId/zone/:zoneId', element: <AdminProtectedRoute><ZonePage /></AdminProtectedRoute> },
        { path: 'cities/:cityId', element: <AdminProtectedRoute><CityPage /></AdminProtectedRoute> },
        { path: 'cities', element: <AdminProtectedRoute><CitiesPage /></AdminProtectedRoute> },
        { path: 'users/:userId', element: <ProtectedRoute><UserPage /></ProtectedRoute> },
        { path: 'users', element: <AdminProtectedRoute><UsersPage /></AdminProtectedRoute> },
        { path: 'app-accesses/:appAccessId', element: <AdminProtectedRoute><AppAccessPage /></AdminProtectedRoute> },
        { path: 'app-accesses', element: <AdminProtectedRoute><AppAccessesPage /></AdminProtectedRoute> },
        { path: 'tickets/:ticketId', element: <ProtectedRoute><TicketPage /></ProtectedRoute> },
        { path: 'tickets', element: <ProtectedRoute><TicketsPage /></ProtectedRoute> },
        { path: 'messages/:messageId', element: <ProtectedRoute><MessagePage /></ProtectedRoute> },
        { path: 'messages', element: <ProtectedRoute><MessagesPage /></ProtectedRoute> },
        { path: 'search/:query', element: <ProtectedRoute><SearchPage /></ProtectedRoute> },
        { path: 'statistics/tickets/', children: [
            { path: ':from/:to/:groupBy/:cityIds', element: <OwnerProtectedRoute><TicketStatisticsPage /></OwnerProtectedRoute> },
            { path: ':from/:to/:groupBy', element: <OwnerProtectedRoute><TicketStatisticsPage /></OwnerProtectedRoute> },
            { path: ':from/:to', element: <OwnerProtectedRoute><TicketStatisticsPage /></OwnerProtectedRoute> },
            { path: '', element: <OwnerProtectedRoute><TicketStatisticsPage /></OwnerProtectedRoute> },
          ]
        },
        { path: 'statistics/messages/', children: [
            { path: ':from/:to/:groupBy/:cityIds', element: <AdminProtectedRoute><MessageStatisticsPage /></AdminProtectedRoute> },
            { path: ':from/:to/:groupBy', element: <AdminProtectedRoute><MessageStatisticsPage /></AdminProtectedRoute> },
            { path: ':from/:to', element: <AdminProtectedRoute><MessageStatisticsPage /></AdminProtectedRoute> },
            { path: '', element: <AdminProtectedRoute><MessageStatisticsPage /></AdminProtectedRoute> },
          ]
        },
        { path: 'home', element: <ProtectedRoute><HomePage /></ProtectedRoute> },
        { path: '*', element: <Navigate to="/dashboard/404" replace={true} /> },
      ],
    },
    {
      path: '/customer',
      element: <CustomerLayout />,
      children: [
        { path: '/customer/tickets', element: <CustomerProtectedRoute><CustomerTicketsPage /></CustomerProtectedRoute> },
        { path: '/customer/sign-in', element: <OnlyPublicRoute><CustomerSignInPage /></OnlyPublicRoute> },
      ],
    },
    {
      path: '/',
      element: <Outlet />,
      children: [
        { path: '/sign-in', element: <OnlyPublicRoute><SignInPage /></OnlyPublicRoute> },
        { path: '/customer/sign-in', element: <OnlyPublicRoute><CustomerSignInPage /></OnlyPublicRoute> },
        { path: '*', element: <Navigate to="/dashboard/404" replace={true} /> },
      ],
    },
    { index: true, element: <IndexRoute /> },
  ])
}
