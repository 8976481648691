import PropTypes from 'prop-types'

import Card                                  from '@mui/material/Card'
import Timeline                              from '@mui/lab/Timeline'
import TimelineDot                           from '@mui/lab/TimelineDot'
import Typography                            from '@mui/material/Typography'
import CardHeader                            from '@mui/material/CardHeader'
import TimelineContent                       from '@mui/lab/TimelineContent'
import TimelineSeparator                     from '@mui/lab/TimelineSeparator'
import TimelineConnector                     from '@mui/lab/TimelineConnector'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import { useEffect, useState }               from 'react'
import { DateTime, Duration }                from 'luxon'
import { useApi }                            from '../utils/api'
import { useTranslation }                    from 'react-i18next'
import { fDateTime }                         from '../utils/textutils'
import { Box, Divider, Stack }               from '@mui/material'
import Iconify                               from '../ui/components/Iconify'
import EmptyListWidget                       from './EmptyListWidget'

// ----------------------------------------------------------------------

export default function MessagesRealtimeList () {

  const [messages, setMessages] = useState([])
  const [lastUpdatedTime, setLastUpdatedTime] = useState(DateTime.now().minus(Duration.fromObject({ minutes: 10 })).toUTC())
  const api = useApi()
  const { t } = useTranslation()

  const loadMessages = async () => {
    const now = DateTime.now().toUTC()
    api.message.list(lastUpdatedTime.toMillis()).then(response => {
      setMessages((prev) => {
        return [...new Map([...response.data, ...prev].map(item =>
          [item['id'], item])).values()]
      })
      setLastUpdatedTime((prev) => prev > now ? prev : now)
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => loadMessages(), 10000)
    return () => clearTimeout(timeout)
  }, [lastUpdatedTime])

  useEffect(() => {
    loadMessages()
  }, [])

  return <MessagesTimeline
    title={t('Last messages')}
    subheader={t('Last received and sent messages')}
    emptyText={t('No recent messages')}
    list={messages}
  />
}

function MessagesTimeline ({ title, subheader, emptyText, list, ...other }) {
  return (
    <>
    {list.length === 0 && <EmptyListWidget title={emptyText} />}
    {list.length > 0 && <Card {...other}>
        <CardHeader title={title} subheader={subheader}/>
        <Box sx={{
          maxHeight: '50vh',
          overflowY: 'scroll',
        }}>
          <Timeline
            sx={{
              m: 0,
              p: 3,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {list.map((message, index) => (
              <MessageItem key={message.id} message={message} lastTimeline={index === list.length - 1}/>
            ))}
          </Timeline>
        </Box>
      </Card>}
    </>
  )
}

MessagesTimeline.propTypes = {
  list: PropTypes.array,
  subheader: PropTypes.string,
  title: PropTypes.string,
  emptyText: PropTypes.string,
}

// ----------------------------------------------------------------------

function MessageItem ({ message, lastTimeline }) {
  const { deliveryStatus, sent, received, telFrom, telTo, content } = message
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (received !== null && 'primary') ||
            (deliveryStatus === 'waiting' && 'primary') ||
            (deliveryStatus === 'sent' && 'primary') ||
            (deliveryStatus === 'delivered' && 'success') ||
            (deliveryStatus === 'notDelivered' && 'warning') ||
            (deliveryStatus === 'notDeliverable' && 'warning') ||
            (deliveryStatus === 'delivered' && 'error') ||
            'error'
          }
        />
        {lastTimeline ? null : <TimelineConnector/>}
      </TimelineSeparator>

      <TimelineContent>
        <Stack direction={'horizontal'} gap={1}>
          <Iconify
            icon={sent ? 'material-symbols:call-made' : 'material-symbols:call-received'}
            sx={{ height: '1em', color: sent ? 'success.main' : 'text.disabled' }}/>
          <Iconify icon={'material-symbols:open-in-phone'} sx={{ height: '1em', color: 'text.disabled' }}/>
          <Typography variant="subtitle2">{telFrom}</Typography>
          <Divider sx={{ width: '10px' }}/>
          <Iconify icon={'material-symbols:settings-input-antenna'} sx={{ height: '1em', color: 'text.disabled' }}/>
          <Typography variant="subtitle2">{telTo}</Typography>
        </Stack>
        {(sent ?? received) && <Box><Typography variant="caption">{fDateTime(sent ?? received)}</Typography></Box>}
        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
          {content}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

MessageItem.propTypes = {
  message: PropTypes.object,
  lastTimeline: PropTypes.bool,
}
