import { BaseBreadcrumbs }     from '../ui/components/BaseBreadcrumbs'
import React                   from 'react'
import { Container, Grid }     from '@mui/material'
import { useTranslation }      from 'react-i18next'
import MessagesRealtimeList    from './MessagesRealtimeList'
import Typography              from '@mui/material/Typography'
import { MessagesPage }        from '../message/MessagesPage'
import { TicketsRealtimeList } from './TicketsRealtimeList'
import Page                    from '../ui/components/Page'

const HomePage = () => {

  const { t } = useTranslation()

  return (
    <Page title={t('Home')}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumbs-messages'}
                         title={null}
                         items={[]}/>

        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <TicketsRealtimeList />
          </Grid>
          <Grid item xs={12} md={5}>
            <MessagesRealtimeList />
          </Grid>
        </Grid>
      </Container>
    </Page>)
}

export default HomePage