import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation }                   from 'react-i18next'
import {
  Alert, Autocomplete,
  Avatar,
  Box,
  Button, Checkbox, Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel, Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
}                                 from '@mui/material'
import { useApi }                                     from '../utils/api'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar }          from 'notistack'
import ShortFormControl         from '../ui/components/short-form-control'
import AlertSimpleDialog        from '../ui/components/alert-simple-dialog'
import Iconify                  from '../ui/components/Iconify'
import Divider                  from '@mui/material/Divider'
import Consts                   from '../consts'
import { BaseBreadcrumbs }      from '../ui/components/BaseBreadcrumbs'
import Page                     from '../ui/components/Page'
import ScheduleZoneChangeDialog from './ScheduleZoneChangeDialog'
import BaseTable                from '../ui/components/base-table'
import { fDateTime }            from '../utils/textutils'
import ZoneChangeDialog         from './ZoneChangeDialog'
import ZoneChangeDeleteDialog   from './ZoneChangeDeleteDialog'

const dayOffList = ['01.01.', '06.01.', '01.05.', '08.05.', '05.07.', '29.08.', '01.09.', '15.11.', '28.10.', '01.11.', '17.11.', '24.12.', '25.12.', '26.12.']

const mergeDaysForPrices = (input, t) => {
  let days = [
    { day: 'common', title: t('common-prices'), items: [null] },
    { day: 'holidays', title: t('holidays-prices'), items: [] },
    ...getDays({t})
  ]
  days.forEach((day, index) => {
    days[index].items = input[day.day] ?? (day.day === 'common' ? [null] : [])
  })
  return days
}

export default function ZonePage () {

  const api = useApi()
  const navigate = useNavigate()
  const { cityId, zoneId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [city, setCity] = useState(null)
  const [zone, setZone] = useState(null)
  const [values, setValues] = useState({
    cityId: cityId,
    name: '',
    keyword: '',
    pattern: '',
    active: false,
    unit: 'hours',
    unitDuration: 1,
    prices: {},
    availability: [],
    unavailableDays: [],
    holidays: [],
    unitLimit: null,
    priceLimit: null,
    reminderTime: null,
    presaleTime: null,
    longPresaleTime: null,
    defaultOrderUnit: null,
    cumulativeResetGapTime: null,
    freeParkingMinutes: null,
  })
  const [presaleTimeEnabled, setPresaleTimeEnabled] = useState(false)
  const [longPresaleEnabled, setLongPresaleEnabled] = useState(false)
  const [cumulativeResetInDayEnabled, setCumulativeResetInDayEnabled] = useState(false)
  const [cutAtMidnight, setCutAtMidnight] = useState(false)
  const [divideFirstUnitToHalf, setDivideFirstUnitToHalf] = useState(false)

  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [forceReloadZoneChangesCounter, setForceReloadZoneChangesCounter] = useState(0)

  const changesColumns = [
    {
      field: 'scheduled', headerName: t('Scheduled'), flex: 1, sortable: false, filterable: false,
      renderCell: (cell) => {
        return cell.row.scheduled ? fDateTime(cell.row.scheduled) : '---'
      }
    },
    {
      field: 'executed', headerName: t('Executed'), flex: 1, sortable: false, filterable: false,
      renderCell: (cell) => {
        return cell.row.executed ? fDateTime(cell.row.executed) : '---'
      }
    },
    { field: 'id', headerName: '#', flex: 1, sortable: false, filterable: false, renderCell: (cell) => {
      return <>
        <ZoneChangeDialog zone={cell.row.originalZoneData} changedZone={cell.row.changedZoneData} />
        {!cell.row.executed && <ZoneChangeDeleteDialog zoneChange={cell.row} onResult={(res) => {
          if (res) {
            setForceReloadZoneChangesCounter((prev) => prev + 1)
          }}} />}
        </>
    }},

  ]

  useEffect(() => {
    (async () => {
      if (!isNaN(zoneId)) {
        let zone = await api.zone.detail(zoneId).catch(() => ({}))
        setZone(zone)
        setValues({
          ...zone,
          prices: mergeDaysForPrices(zone.prices, t),
        })
        setPresaleTimeEnabled((zone.flags & Consts.Zone.flagPresaleEnabled) !== 0)
        setLongPresaleEnabled((zone.flags & Consts.Zone.flagLongPresaleEnabled) !== 0)
        setCumulativeResetInDayEnabled((zone.flags & Consts.Zone.flagCumulativeResetInDayEnabled) !== 0)
        setCutAtMidnight((zone.flags & Consts.Zone.flagCutAtMidnight) !== 0)
        setDivideFirstUnitToHalf((zone.flags & Consts.Zone.flagDivideFirstUnitToHalf) !== 0)
      }
    })()
  }, [zoneId])

  useEffect(() => {
    (async () => {
      let _city = await api.city.detail(cityId).catch(() => ({}))
      setCity(_city)
    })()
  }, [cityId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.zone.delete(zoneId).then(() => {
        enqueueSnackbar(t('City deleted'), {
          variant: 'success'
        })
        navigate(`/cities/${cityId}`)
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete city'), {
          variant: 'error'
        })
      })
    }
  }


  const prepareFormData = () => {
    return {
      ...values,
      prices: values.id ? (values.prices?.filter(price => price.items.length > 0).reduce((acc, curr) => {
        acc[curr.day] = curr.items.map(it => parseInt(it))
        return acc
      }, {}) ?? {}) : {},
      flags: (presaleTimeEnabled ? Consts.Zone.flagPresaleEnabled : 0) | (longPresaleEnabled ? Consts.Zone.flagLongPresaleEnabled : 0) | (cumulativeResetInDayEnabled ? Consts.Zone.flagCumulativeResetInDayEnabled : 0) | (cutAtMidnight ? Consts.Zone.flagCutAtMidnight : 0) | (divideFirstUnitToHalf ? Consts.Zone.flagDivideFirstUnitToHalf : 0),
      freeParkingMinutes: values.freeParkingMinutes ? parseInt(values.freeParkingMinutes) : null
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = prepareFormData()
    try {
      let zone = await api.zone.save(data)
      navigate(`/cities/${cityId}/zone/${zone.id}`)
      enqueueSnackbar(t('Zone saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save zone'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value })
  }

  return (
    <Page title={t('Zone')}>
    <Container component="main">
      <BaseBreadcrumbs key={'breadcrumb-zone'}
                       title={isNaN(zoneId) ? t('Create new zone') : values.name}
                       items={[
                         { href: `/cities`, title: t('Cities') },
                         { href: `/cities/${cityId}`, title: city?.name ?? '' },
                       ]} />
      <Grid component={'form'} onSubmit={onSubmit} item mb={4} xs={12}>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Grid container sx={{ width: '100%' }} spacing={2}>
            <Grid item xs={12} md={6}>
              <ShortFormControl
                name={'name'}
                title={t('Title')}
                value={values.name}
                handleChange={handleChange}/>
            </Grid>
            <Grid item xs={6} md={6}>
              <ShortFormControl
                name={'keyword'}
                title={t('Keyword')}
                value={values.keyword}
                handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <ShortFormControl
                name={'pattern'}
                title={t('Pattern')}
                value={values.pattern}
                handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox/>} checked={values.active} onChange={(e) => {
                  handleChangeDirect('active', e.target.checked)
                }} label={t('Active')}/>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h6'} sx={{ mt: 2, color: 'text.secondary' }}>{t('Units')}</Typography>
              <Typography variant={'body2'} sx={{ color: 'text.disabled' }}>{t('Units_description')}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="parent-category-select-label">{t('Unit')}</InputLabel>
                <Select
                  labelId="unit-select-label"
                  id="unit-select"
                  value={values?.unit}
                  label={t('Unit')}
                  onChange={handleChange('unit')}
                >
                  <MenuItem value={'minutes'}>{t('Minutes')}</MenuItem>
                  <MenuItem value={'hours'}>{t('Hours')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormGroup>
                <ShortFormControl
                  name={'unitDuration'}
                  title={t('Unit duration')}
                  value={values.unitDuration ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormGroup>
                <ShortFormControl
                  name={'defaultOrderUnit'}
                  title={t('Default order unit')}
                  value={values.defaultOrderUnit ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h6'} sx={{ mt: 2, color: 'text.secondary' }}>{t('Limits')}</Typography>
              <Typography variant={'body2'} sx={{ color: 'text.disabled' }}>{t('Limits_description')}</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormGroup>
                <ShortFormControl
                  name={'unitLimit'}
                  title={t('Unit limit')}
                  value={values.unitLimit ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormGroup>
                <ShortFormControl
                  name={'priceLimit'}
                  title={t('Price limit')}
                  value={values.priceLimit ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup>
                <ShortFormControl
                  name={'reminderTime'}
                  title={t('Reminder time')}
                  value={values.reminderTime ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <Switch checked={cumulativeResetInDayEnabled} onChange={(e) => {
                setCumulativeResetInDayEnabled(e.target.checked)
              }}/>
              <FormGroup sx={{ width: '100%' }}>
                <ShortFormControl
                  name={'cumulativeResetGapTime'}
                  title={t('Cumulative gap time (minutes)')}
                  value={values.cumulativeResetGapTime ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                    disabled: !cumulativeResetInDayEnabled
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup sx={{ pl: 1 }}>
                <FormControlLabel control={<Switch />} checked={cutAtMidnight} onChange={(e) => {
                  setCutAtMidnight(e.target.checked)
                }} label={t('cutAtMidnight')}/>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup sx={{ pl: 1 }}>
                <FormControlLabel control={<Switch />} checked={divideFirstUnitToHalf} onChange={(e) => {
                  setDivideFirstUnitToHalf(e.target.checked)
                }} label={t('divideFirstUnitToHalf')}/>
                <Typography variant={'caption'} sx={{ ml: 6, color: 'text.secondary' }}>{t('divideFirstUnitToHalfDesc')}</Typography>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h6'} sx={{ mt: 2, color: 'text.secondary' }}>{t('Presale')}</Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <Switch checked={presaleTimeEnabled} onChange={(e) => {
                setPresaleTimeEnabled(e.target.checked)
              }}/>
              <FormGroup sx={{ width: '100%' }}>
                <ShortFormControl
                  name={'presaleTime'}
                  title={t('Presale time (minutes)')}
                  value={values.presaleTime ?? ''}
                  handleChange={handleChange}
                  inputProps={{
                    type: 'number',
                    disabled: !presaleTimeEnabled
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              <Switch checked={longPresaleEnabled} onChange={(e) => {
                setLongPresaleEnabled(e.target.checked)
              }}/>
              <FormGroup sx={{ width: '100%' }}>
                <TextField
                  label={t('Long presale time (minutes)')}
                  value={values.longPresaleTime ?? ''}
                  onChange={(e) => handleChangeDirect('longPresaleTime', e.target.value) }
                  helperText={t('required verification')}
                  variant="standard"
                  inputProps={{
                    type: 'number',
                    disabled: !longPresaleEnabled,
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }}/>
          <Grid container>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant={'h6'} sx={{ color: 'text.secondary', my: 2 }}>{t('Prices')}</Typography>
              <Grid container>
                <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                  <FormGroup sx={{ width: '100%' }}>
                    <TextField
                      label={t("Free time on '0' unit (minutes)")}
                      value={values.freeParkingMinutes ?? ''}
                      onChange={(e) => handleChangeDirect('freeParkingMinutes', e.target.value) }
                      variant="standard"
                      inputProps={{
                        type: 'number',
                      }}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              {values.id && values.prices.map((priceObject, index) => {
                return <Prices priceData={priceObject}
                               key={`prices-${priceObject.day}`}
                               onChange={(val) => {
                                 let prices = values.prices
                                 prices[index].items = val
                                 handleChangeDirect('prices', prices)
                               }}
                />
              })}
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              {values.id && <TimeAvailability availability={values.availability}
                                              onChange={(val) => handleChangeDirect('availability', val)}/>}
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="holidays"
                options={dayOffList}
                value={values.holidays ?? []}
                onChange={(e, newValue) => {
                  handleChangeDirect('holidays', newValue.filter(it => /(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[1,2])\./.test(it)))
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t('Holidays')}
                    helperText={t('During this days is applied price defined for holidays')}
                  />
                )}
              />
              <Link href={'#'} variant={'body2'} onClick={(e) => {
                e.preventDefault()
                let selectedDays = values.holidays ?? []
                dayOffList.forEach((it) => {
                  if (selectedDays.indexOf(it) === -1) {
                    selectedDays.push(it)
                  }
                })
                handleChangeDirect('holidays', selectedDays)
              }}>{t('Add all national days off')}</Link>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                id="day-off"
                options={dayOffList}
                value={values.unavailableDays ?? []}
                onChange={(e, newValue) => {
                  handleChangeDirect('unavailableDays', newValue.filter(it => /(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[1,2])\./.test(it)))
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t('Unavailable days')}
                    helperText={t('Service is not available during this days')}
                  />
                )}
              />
              <Link href={'#'} variant={'body2'} onClick={(e) => {
                e.preventDefault()
                let selectedDays = values.unavailableDays ?? []
                dayOffList.forEach((it) => {
                  if (selectedDays.indexOf(it) === -1) {
                    selectedDays.push(it)
                  }
                })
                handleChangeDirect('unavailableDays', selectedDays)
              }}>{t('Add all national days off')}</Link>
            </Grid>
          </Grid>
        </Paper>
        <Stack direction={'row'} sx={{ mt: 2, px: 1 }}>
          <Button type={'submit'} variant={'contained'}>{t('Save zone')}</Button>
          {!isNaN(zoneId) && zone !== null && <>
            <ScheduleZoneChangeDialog zone={zone} getFormData={() => prepareFormData()} onResult={() => {
              setForceReloadZoneChangesCounter((prev) => prev + 1)
            }} />
            <Box sx={{ flex: '1' }} />
            <Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }}
                    onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>
          </>}
        </Stack>
      </Grid>
      {!isNaN(zoneId) && <Grid item xs={12}>
        <Stack direction="row" alignItems="center" mb={5} spacing={2}>
          <Typography variant="h4" gutterBottom>{t('Scheduled changes')}</Typography>
        </Stack>
        <BaseTable
          columns={changesColumns}
          query={null}
          forceReloadCounter={forceReloadZoneChangesCounter}
          loadCallback={async (query, page, pageSize) => {
            try {
              return await api.zone.scheduledChangesList(zoneId)
            } catch (e) {
              console.log('Failed to load scheduled changes for zone', e)
              return []
            }
          }}
        />
      </Grid>}
      <AlertSimpleDialog
        title={t('Delete zone')}
        message={t('delete-zone-confirm-msg', { name: values.name })}
        open={shownDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </Container>
    </Page>
  )
}

const Prices = ({ priceData, onChange }) => {

  const { t } = useTranslation()
  const [internalPrices, setPrices] = useState(priceData.items ?? [])

  useEffect(() => {
    onChange(internalPrices)
  }, [internalPrices])

  const setPrice = (index, value) => {
    setPrices(prev => {
      prev[index] = value
      return [...prev]
    })
  }

  return (<>
    <Stack direction={'column'} spacing={2}>
      {internalPrices.length > 0 && <Divider>
        <Chip label={priceData.title} size="small"/>
      </Divider>}
      <Stack direction={'row'} spacing={2} useFlexGap flexWrap="wrap">
        {internalPrices.map((price, index) => {
          return (
            <Stack direction={'row'} spacing={1} alignItems={'center'} key={`list-item-${index}`}>
              <Avatar sx={{ width: 24, height: 24, bgcolor: 'primary.main' }}>{index + 1}</Avatar>
              <TextField
                id={`price-${index}`}
                label={<Trans i18nKey={index === internalPrices.length - 1 ? 'price-unit-last-and-next' : 'price-unit-exact'} values={{ count: index + 1 }} />}
                helperText={t('in-euro-cent')}
                type="number"
                value={internalPrices[index] ?? ''}
                onChange={(e) => {
                  setPrice(index, e.target.value)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
              />
              <IconButton edge="end" onClick={(e) => {
                setPrices((prev) => [...prev.filter((val, filterIndex) => filterIndex !== index)])
              }}>
                <Iconify icon={'material-symbols:delete'}/>
              </IconButton>

              <IconButton edge="end" onClick={(e) => {
                setPrices((prev) => {
                  prev.splice(index + 1, 0, null)
                  return [...prev]
                })
              }}>
                <Iconify icon={'material-symbols:add'}/>
              </IconButton>
            </Stack>
          )
        })}
      </Stack>
      {internalPrices.length === 0 && <Alert variant="outlined" severity='info' action={<Button edge="end" onClick={(e) => setPrices((prev) => [...prev, null])}>{t('Add')}</Button>}>
        <strong>{priceData.title}</strong> {t('has no prices defined')}
      </Alert>}
    </Stack>
  </>)
}

const getDays = ({ t }) => [
  { day: 'monday', title: t('Monday'), items: [] },
  { day: 'tuesday', title: t('Tuesday'), items: [] },
  { day: 'wednesday', title: t('Wednesday'), items: [] },
  { day: 'thursday', title: t('Thursday'), items: [] },
  { day: 'friday', title: t('Friday'), items: [] },
  { day: 'saturday', title: t('Saturday'), items: [] },
  { day: 'sunday', title: t('Sunday'), items: [] }
]

const TimeAvailability = ({ availability, onChange }) => {

  const { t } = useTranslation()
  const mergeDays = (input) => {
    let days = getDays({ t })
    days.forEach(day => {
      day.items = input.filter(it => it.day === day.day)
    })
    return days
  }
  const [items, setItems] = useState(mergeDays(availability))

  const onChangeCallback = useCallback(() => {
    onChange(items.flatMap(it => it.items.map(time => ({ ...time, day: it.day }))))
  }, [items])

  return (<>
    <Typography variant={'h6'} sx={{ my: 2, color: 'text.secondary' }}>{t('Time availability')}</Typography>
    <Stack direction={'row'} spacing={2} useFlexGap flexWrap="wrap"
           divider={<Divider orientation="vertical" flexItem/>}>
      {items.map((day, index) => (
        <TimeAvailabilityItem key={`time-availability-${index}`} day={day} onItemsChanged={(items) => {
          setItems((prev) => {
            prev[index].items = items
            return [...prev]
          })
          onChangeCallback()
        }}/>))}
    </Stack>
  </>)
}

const TimeAvailabilityItem = ({ day, onItemsChanged }) => {

  const [times, setTimes] = useState(day.items ?? [])
  const { t } = useTranslation()

  useEffect(() => {
    onItemsChanged(times)
  }, [times])

  return <>
    <Stack direction={'column'} spacing={2}>
      <Divider sx={{ my: 2 }}>
        <Chip label={day.title} size="small"/>
      </Divider>
      {times.map((it, itIndex) => {
        return <Stack direction={'row'} spacing={1} alignItems={'flex-end'} key={`list-item-${itIndex}`}>
          <Avatar sx={{ width: 24, height: 24, bgcolor: 'primary.light' }}>{itIndex + 1}</Avatar>
          <TextField
            id={`time-${day.id}-${itIndex}-from`}
            label={t('From')}
            variant={'standard'}
            type="time"
            value={it.from ?? ''}
            onChange={(e) => {
              setTimes((prev) => {
                prev[itIndex].from = e.target.value
                return [...prev]
              })
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
            }}
            sx={{ width: 110 }}
          />
          <TextField
            id={`time-${day.id}-${itIndex}-to`}
            label={t('To')}
            variant={'standard'}
            type="time"
            value={it.to ?? ''}
            onChange={(e) => {
              setTimes((prev) => {
                prev[itIndex].to = e.target.value
                return [...prev]
              })
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1,
            }}
            sx={{ width: 110 }}
          />
          <IconButton edge="end" onClick={(e) => {
            setTimes((prev) => prev.filter((val, filterIndex) => filterIndex !== itIndex))
          }}>
            <Iconify icon={'material-symbols:delete'}/>
          </IconButton>
        </Stack>
      })}
      <Button variant="outlined" onClick={(e) => {
        setTimes((prev) => [...prev, { from: null, to: null }])
      }}>
        <Iconify icon={'material-symbols:add'}/>
      </Button>
    </Stack>
  </>
}