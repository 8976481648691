import { BaseBreadcrumbs }                                    from '../ui/components/BaseBreadcrumbs'
import React, { useEffect, useState }                                     from 'react'
import { Box, Button, Container, Divider, Grid, IconButton, Link, Stack } from '@mui/material'
import { Trans, useTranslation }                                          from 'react-i18next'
import Typography                                             from '@mui/material/Typography'
import Page                           from '../ui/components/Page'
import { useParams }                  from 'react-router-dom'
import palette                        from '../ui/theme/palette'
import { useApi }                     from '../utils/api'
import { useSnackbar }                from 'notistack'
import Iconify                        from '../ui/components/Iconify'
import { fDateFromTo, fDateTimeAuto } from '../utils/textutils'
import { DateTime }                   from 'luxon'
import Label                          from '../ui/components/Label'
import EmptyListWidget                from '../home/EmptyListWidget'
import { Link as RouterLink }         from 'react-router-dom'
import Loader                         from '../ui/components/Loader'

const PAGE_LENGTH = 50

const HomePage = () => {

  const api = useApi()
  const { t } = useTranslation()
  const { query } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [page, setPage] = useState(0)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState(null)
  const sanitizedQuery = decodeURIComponent(query)

  useEffect(() => {
    setLoading(() => true)
    api.message.list(null, null, null, sanitizedQuery, page, PAGE_LENGTH).then(({ data, totalCount }) => {
      setShowLoadMore((c) => data.length === PAGE_LENGTH)
      setItems((prev) => {
        return [...(page === 0 ? [] : [...(prev ?? [])]), ...data]
      })
    }).catch(e => {
      enqueueSnackbar(t('Search failed'), {
        variant: 'error'
      })
    }).finally(() => {
      setLoading(() => false)
    })
  }, [query, page])

  return (
    <Page title={`${t('Search')} - ${sanitizedQuery}`}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumbs-messages'}
                         title={t('Search')}
                         items={[ ]}/>
        <Stack direction={'row'} sx={{ mb: 5 }}>
          <Typography variant="h4" sx={{ color: palette.text.disabled }}>
            <Trans i18nKey={'searchingFor'} values={{ query: sanitizedQuery }} />
          </Typography>
          <Loader loading={loading} direction={'row'} size={'1.5em'} justifyContent={'start'} />
        </Stack>
        <Grid container direction={'column'} alignItems={'center'}>
          <Grid item xs={12} sx={{ bgcolor: 'background.paper' }}>
            {items?.map((message) => <MessageItem message={message} key={`message-${message.id}`} />)}
          </Grid>
          {items?.length === 0 && <EmptyListWidget title={<Trans i18nKey={'searchNotFound'} values={{ query: query }} />} />}
          <Grid item xs={12} sx={{ mt: 2 }}>
            {showLoadMore && <Button variant={'outlined'} onClick={(e) => {
              setPage((prev) => prev + 1)
            }}>{t('Load more')}</Button>}
          </Grid>
        </Grid>
      </Container>
    </Page>)
}

const MessageItem = ({message}) => {

  const ticketStatusColor = message.ticket && DateTime.fromISO(message.ticket.validTo) > DateTime.now() ? 'success.main' : 'text.disabled'
  const isOutgoing = message.received === null
  const tel = isOutgoing ? message.telTo : message.telFrom

  return <Box sx={{ width: '100%' }}>
    <Stack direction={'row'} spacing={3} sx={{ px: 3, py: 2 }}>
      <Iconify icon={'material-symbols:sms'} sx={{ height: 22, width: 22, color: palette.text.disabled }} />
      <Link component={RouterLink} to={`/search/${encodeURIComponent(tel)}`} variant={'body1'} flexBasis={130} color={'text.secondary'}>{tel}</Link>
      <Stack direction={'column'} flex={1}>
        <Stack direction={'row'} spacing={3} sx={{ alignItems: 'start' }}>
          <Typography variant={'body2'} flex={1}>{message.content}</Typography>
          <Typography variant={'body2'} color={palette.text.disabled}>{fDateTimeAuto(message.received ?? message.sent)}</Typography>
          <IconButton component={RouterLink} to={`/messages/${message.id}`} size={'small'}>
            <Iconify icon={'material-symbols:chevron-right'} />
          </IconButton>
        </Stack>
        {message.ticket && isOutgoing && <>
          <Divider sx={{ mt: 1 }} />
          <Stack direction={'row'} spacing={2} sx={{ mt: 1, alignItems: 'start' }}>
            <Iconify icon={'material-symbols:confirmation-number-outline'} sx={{ height: 22, width: 22, color: ticketStatusColor }} />
            <Label color={'secondary'} startIcon={<Iconify icon={'material-symbols:directions-car'} sx={{ height: 15, width: 15 }} />}>{message.ticket.licence}</Label>
            <Label>{message.ticket.zone?.keyword}</Label>
            <Label>{message.ticket.city?.name}</Label>
            <Box flexGrow={1} />
            <Typography variant={'body2'} color={ticketStatusColor}>{fDateFromTo(message.ticket.validFrom, message.ticket.validTo)}</Typography>
            <IconButton component={RouterLink} to={`/tickets/${message.ticket.id}`} size={'small'}>
              <Iconify icon={'material-symbols:chevron-right'} />
            </IconButton>
          </Stack>
        </>}
      </Stack>
    </Stack>
    <Divider />
  </Box>
}

export default HomePage