import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline                    from '@mui/material/CssBaseline';
import TextField                      from '@mui/material/TextField';
import Link                           from '@mui/material/Link';
import Box                            from '@mui/material/Box';
import Typography                     from '@mui/material/Typography';
import Container                      from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useApi }                     from '../utils/api'
import { useNavigate }                from 'react-router-dom'
import { Alert, Stack }               from '@mui/material'
import { useContext, useState }       from 'react'
import { ParkingAppContext }          from '../App'
import Iconify                        from '../ui/components/Iconify'
import { useTranslation }             from 'react-i18next'
import Page                           from '../ui/components/Page'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© Copyright '}
      <Link color="inherit" href="https://mediatex.sk/">
        Mediatex
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInPage() {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const api = useApi()
  const {user, setUser} = useContext(ParkingAppContext)
  const [loginError, setLoginError] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    api.user.login(data.get('email'), data.get('password'))
      .then(user => {
        setUser(user)
        navigate('/')
      })
      .catch(error => {
        setLoginError(error)
      })
  };

  return (
    <Page title={t('Sign In Title')}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Stack direction={'column'} alignItems={'center'} sx={{ mt: 4 }}>
            <Typography variant={'h4'} sx={{ my: 4 }}>{t('Mediatex Parking APP')}</Typography>
            <Box
              component="img"
              sx={{
                mb: { xs: 2, md: 5 },
              }}
              alt="Mediatex Parking Logo"
              src={process.env.PUBLIC_URL+"/static/logo_250_55.png"}
            />
            <Typography component="h1" variant="h5">{t('Sign In Title')}</Typography>
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <Iconify icon={'material-symbols:login'} />
            </Avatar>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('Email Address')}
                name="email"
                autoComplete="email"
                onKeyDown={(e) => setLoginError(null)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('Password')}
                type="password"
                id="password"
                autoComplete="current-password"
                onKeyDown={(e) => setLoginError(null)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('Sign In')}
              </Button>
              {loginError && <Alert severity="error">{t('Unable to login, please try again.')}</Alert>}
            </Box>
          </Stack>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </Page>
  );
}