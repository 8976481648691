import * as React        from 'react';
import Button                                                                      from '@mui/material/Button';
import TextField                                                                   from '@mui/material/TextField';
import Dialog                                                                      from '@mui/material/Dialog';
import DialogActions                                                                           from '@mui/material/DialogActions';
import DialogContent                                                                           from '@mui/material/DialogContent';
import DialogContentText                                                                       from '@mui/material/DialogContentText';
import DialogTitle                                                                             from '@mui/material/DialogTitle';
import { Trans, useTranslation }                                                               from 'react-i18next'
import { useApi }                                                                              from '../utils/api'
import { useSnackbar }                                                                         from 'notistack'
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import AlertSimpleDialog
                                                                                               from '../ui/components/alert-simple-dialog'
import { fDateTime }                                                                           from '../utils/textutils'
import Iconify
                                                                                               from '../ui/components/Iconify'

export default function ZoneChangeDeleteDialog({ zoneChange, onResult }) {

  const api = useApi()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (result) => {
    setOpen(false)
    if (!!onResult) {
      onResult(result)
    }
  };

  return (
    <React.Fragment>
      <IconButton size={'small'} color={'error'} onClick={handleClickOpen}>
        <Iconify icon={'material-symbols:delete'}/>
      </IconButton>
      <AlertSimpleDialog
        title={t('Delete zone change')}
        message={t('delete-zone-change-confirm-msg', { scheduled: fDateTime(zoneChange.scheduled) })}
        open={open}
        onClose={async (result) => {
          if (!result){
            handleClose(result)
            return
          }
          api.zone.deleteZoneChange(zoneChange.id).then(() => {
            enqueueSnackbar(t('Zone change deleted'), {
              variant: 'success'
            })
          }).catch(error => {
            enqueueSnackbar(t('Failed to delete zone change'), {
              variant: 'error'
            })
          }).finally(() => {
            handleClose(result)
          })
        }}
      />
    </React.Fragment>
  );
}