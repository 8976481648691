import { Link as RouterLink }                              from 'react-router-dom'
import BaseTable, { BaseSearch }                           from '../ui/components/base-table'
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material'
import Page                                                from '../ui/components/Page'
import Iconify                                             from '../ui/components/Iconify'
import { useTranslation }                                  from 'react-i18next'
import React, { useState }                                 from 'react'
import { useApi }                                          from '../utils/api'
import { BaseBreadcrumbs }                                 from '../ui/components/BaseBreadcrumbs'
import Label                                               from '../ui/components/Label'

export default function CitiesPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: t('Title'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            variant="body2"
            to={`/cities/${cell.row.id}`}
          >
            {cell.row.name}
          </Link>
        )
      }},
    { field: 'active', headerName: t('Active'), width: 110, sortable: false, filterable: false, renderCell: (cell) => {
      if (cell.row.active) {
        return <Label color={'success'} variant={'ghost'} endIcon={<Iconify icon={'material-symbols:check'} />}>{t('Active')}</Label>
      } else {
        return <Label color={'default'} variant={'ghost'} endIcon={<Iconify icon={'material-symbols:close'} />}>{t('Inactive')}</Label>
      }
    }},
    { field: 'keyword', headerName: t('Keyword'), flex: 1, sortable: false, filterable: false },
    { field: 'phone', headerName: t('Phone'), flex: 1, sortable: false, filterable: false },
    { field: 'recognizedBy', headerName: t('Recognized by'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => cell.row.pattern ? cell.row.pattern : cell.row.keyword },
  ];

  return (<Page title={t('Cities')}>
    <Container>
      <BaseBreadcrumbs key={'breadcrumb-cities'}
                       title={t('Cities')}
                       items={[]} />
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Cities')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/cities/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New city')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.city.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load cities', e)
            return {
              data: [],
              totalCount: 0,
            }
          }
        }}
      />
    </Container>
  </Page>)

}