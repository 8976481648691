import PropTypes from 'prop-types'
import Card                             from '@mui/material/Card'
import Typography                       from '@mui/material/Typography'
import { useEffect, useState }          from 'react'
import { DateTime, Duration }           from 'luxon'
import { useApi }                       from '../utils/api'
import { useTranslation }               from 'react-i18next'
import { fDateTime, fmVisibleDateTime } from '../utils/textutils'
import { Grid, Stack }                  from '@mui/material'
import Iconify                          from '../ui/components/Iconify'
import EmptyListWidget                  from './EmptyListWidget'

// ----------------------------------------------------------------------

export function TicketsRealtimeList () {

  const [tickets, setTickets] = useState([])
  const [lastUpdatedTime, setLastUpdatedTime] = useState(DateTime.now().minus(Duration.fromObject({ minutes: 10 })).toUTC())
  const api = useApi()
  const { t } = useTranslation()

  const loadTickets = async () => {
    const now = DateTime.now().toUTC()
    api.ticket.list(lastUpdatedTime.toMillis()).then(response => {
      setTickets((prev) => {
        return [...new Map([...response.data, ...prev].map(item =>
          [item['id'], item])).values()]
      })
      setLastUpdatedTime((prev) => prev > now ? prev : now)
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => loadTickets(), 10000)
    return () => clearTimeout(timeout)
  }, [lastUpdatedTime])

  useEffect(() => {
    loadTickets()
  }, [])

  return <TicketsTimeline
    emptyText={t('No recent tickets')}
    list={tickets}
  />
}

function TicketsTimeline ({ title, emptyText, list, ...other }) {
  return <>
    {list.length === 0 && <Grid xs={12} item><EmptyListWidget title={emptyText}/></Grid>}
    <Grid container spacing={2} sx={{ maxHeight: '50vh', overflowY: 'scroll' }} {...other}>
      {list.map((ticket, index) => (
        <Grid item
              key={ticket.id}
              xs={6}
              md={6}
              sx={{ p: 2 }}
        >
          <TicketItemWidget
            title={ticket.licence}
            subtitle={ticket.city.name}
            caption={`${fDateTime(ticket.validFrom, fmVisibleDateTime)} - ${fDateTime(ticket.validTo, fmVisibleDateTime)}`}
            color={DateTime.fromISO(ticket.validTo) > DateTime.now() ? 'success.main' : 'secondary.main'}
          />
        </Grid>
      ))}
    </Grid>
  </>
}

TicketsTimeline.propTypes = {
  list: PropTypes.array,
  emptyText: PropTypes.string,
}

// ----------------------------------------------------------------------
export function TicketItemWidget ({ title, subtitle, caption, color = 'text.primary', sx, ...other }) {
  return (
    <Card
      component={Stack}
      spacing={2}
      direction="row"
      sx={{
        px: 2,
        py: 3,
        borderRadius: 2,
        ...sx,
      }}
      {...other}
    >
      <Iconify icon={'material-symbols:confirmation-number-outline'} sx={{ width: 48, height: 48, color: color }}/>
      <Stack spacing={0.5}>
        <Typography variant="h6">{title}</Typography>

        <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>{subtitle}</Typography>
        <Typography variant="caption" sx={{ color: 'text.disabled' }}>{caption}</Typography>
      </Stack>
    </Card>
  )
}

TicketItemWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  caption: PropTypes.string,
}