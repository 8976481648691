module.exports = Object.freeze({
  Zone: {
    flagPresaleEnabled: 1,
    flagLongPresaleEnabled: 2,
    flagCumulativeResetInDayEnabled: 4,
    flagCutAtMidnight: 8,
    flagDivideFirstUnitToHalf: 16,
  },
  City: {
    flagVatEnabled: 1,
  }
})