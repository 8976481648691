import { BaseBreadcrumbs } from '../ui/components/BaseBreadcrumbs'
import React, { useState } from 'react'
import {
  Checkbox,
  Container, FormControlLabel, Stack,
  TableBody, TableCell, TableFooter, TableHead, TableRow,
}                          from '@mui/material'
import { useTranslation }                  from 'react-i18next'
import Page                                from '../ui/components/Page'
import { useApi }                                          from '../utils/api'
import { fExportPrice, fOperator, fOperatorClass, fPrice } from '../utils/textutils'
import CommonStatisticsPage                                from '../common/CommonStatisticsPage'
import styled                              from '@emotion/styled'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&.OperatorRow .MuiTableCell-root': {
    color: '#ffffff',
  },
  '&.OperatorRow.Orange': {
    backgroundColor: '#f16e00',
  },
  '&.OperatorRow.Telekom': {
    backgroundColor: '#e20074',
  },
  '&.OperatorRow.O2': {
    backgroundColor: '#0090d0',
  },
  '&.OperatorRow.Swan': {
    backgroundColor: '#fad210',
    '& .MuiTableCell-root': {
      color: '#000000',
    }
  },
}));

const highlightOperatorKey = 'highlightOperator'
const TicketStatisticsPage = () => {

  const api = useApi()
  const { t } = useTranslation()
  const [highlightOperator, setHighlightOperator] = useState(localStorage.getItem(highlightOperatorKey) === 'true')

  const setHighlightOperatorImpl = (e) => {
    localStorage.setItem(highlightOperatorKey, e.target.checked)
    setHighlightOperator(e.target.checked)
  }

  return (
    <Page title={t('Message statistics')}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumbs-messages'}
                         title={t('Message statistics')}
                         items={[ ]}/>
        <CommonStatisticsPage
          fnApiLoadData={api.message.statistics}
          pathPrefix={'/statistics/messages'}
          defaultGroupBy={['city', 'operator']}
          allowedGroupBy={['city','operator','msgtype', 'deliverystatus', 'chargeability']}
          exportFileName={'messages'}
          exportRow={(row, filter) => {
            let obj = {}
            if (filter.groupBy.includes('city')) {
              obj[t('City')] = row.cityName ?? ''
            }
            if (filter.groupBy.includes('operator')) {
              obj[t('Operator')] = fOperator(row.operator)
            }
            if (filter.groupBy.includes('msgtype')) {
              obj[t('Message type')] = row.type ? t(`msgtype-${row.type}`) :''
            }
            if (filter.groupBy.includes('deliverystatus')) {
              obj[t('Delivery status')] = row.deliveryStatus ? t(`deliverystatus-${row.deliveryStatus}`) : ''
            }
            if (filter.groupBy.includes('chargeability')) {
              obj[t('Chargeability')] = row.chareability ? 1 : 0
            }
            obj[t('Count')] = row.count ?? 0
            obj[t('PriceNoVat')] = fExportPrice(row.sumPrice ?? 0)
            obj[t('Vat')] = fExportPrice(row.sumVat ?? 0)
            obj[t('PriceWithVat')] = fExportPrice(row.sumPriceWithVat ?? 0)
            return obj
          }}
          customOptions={
            <Stack key={'stack-option'} alignItems={'end'}>
              <FormControlLabel control={<Checkbox checked={highlightOperator} onChange={setHighlightOperatorImpl} />} label={t('Highlight operator')} />
            </Stack>
          }
        >
          <StatsTableHeader />
          <StatsTableBody highlightOperator={highlightOperator} />
          <StatsTableFooter />
        </CommonStatisticsPage>
      </Container>
    </Page>)
}

const StatsTableHeader = ({ t, filter, data }) => {

  return <TableHead>
    <TableRow>
      {filter.groupBy.includes('city') && <TableCell>{t('City')}</TableCell>}
      {filter.groupBy.includes('operator') && <TableCell>{t('Operator')}</TableCell>}
      {filter.groupBy.includes('msgtype') && <TableCell>{t('Message type')}</TableCell>}
      {filter.groupBy.includes('deliverystatus') && <TableCell>{t('Delivery status')}</TableCell>}
      {filter.groupBy.includes('chargeability') && <TableCell>{t('Chargeability')}</TableCell>}
      <TableCell align={'right'}>{t('Count')}</TableCell>
      <TableCell align={'right'}>{t('PriceNoVat')}&nbsp;€</TableCell>
      <TableCell align={'right'}>{t('Vat')}&nbsp;€</TableCell>
      <TableCell align={'right'}>{t('PriceWithVat')}&nbsp;€</TableCell>
    </TableRow>
  </TableHead>
}

const StatsTableBody = ({ t, filter, data, highlightOperator }) => {

  return <TableBody>
    {data.map((row, i) => <StyledTableRow className={highlightOperator ? `OperatorRow ${fOperatorClass(row.operator)}` : ''} key={`row-${i}`}>
      {filter.groupBy.includes('city') && <TableCell>{row.cityName ?? ''}</TableCell>}
      {filter.groupBy.includes('operator') && <TableCell>{fOperator(row.operator)}</TableCell>}
      {filter.groupBy.includes('msgtype') && <TableCell>{row.type ? t(`msgtype-${row.type}`) :''}</TableCell>}
      {filter.groupBy.includes('deliverystatus') && <TableCell>{row.deliveryStatus ? t(`deliverystatus-${row.deliveryStatus}`) : ''}</TableCell>}
      {filter.groupBy.includes('chargeability') && <TableCell>{row.chargeability ? t(`yes`) : t('no')}</TableCell>}
      <TableCell align={'right'}>{row.count ?? 0}</TableCell>
      <TableCell align={'right'}>{fPrice(row.sumPrice ?? 0)}</TableCell>
      <TableCell align={'right'}>{fPrice(row.sumVat ?? 0)}</TableCell>
      <TableCell align={'right'}>{fPrice(row.sumPriceWithVat ?? 0)}</TableCell>
    </StyledTableRow>)}
  </TableBody>
}

const StatsTableFooter = ({ t, filter, data }) => {

  const sum = (prop) => data.map((row) => row[prop] ?? 0).reduce((acc, curr) => acc+curr, 0)
  const countOfEmptyCells = ['city', 'operator', 'msgtype', 'deliverystatus', 'chargeability'].map(groupBy => filter.groupBy.includes(groupBy)).filter(it => it).length - 1

  return <TableFooter>
    <TableRow>
      <TableCell component={'th'} scope="row">{t('Total')}</TableCell>
      {Array(countOfEmptyCells).fill().map((_, i) => <TableCell key={`cell-${i}`}>&nbsp;</TableCell>)}
      <TableCell align={'right'}>{sum('count')}</TableCell>
      <TableCell align={'right'}>{fPrice(sum('sumPrice'))}</TableCell>
      <TableCell align={'right'}>{fPrice(sum('sumVat'))}</TableCell>
      <TableCell align={'right'}>{fPrice(sum('sumPriceWithVat'))}</TableCell>
    </TableRow>
  </TableFooter>
}

export default TicketStatisticsPage