import * as React                          from 'react'
import Button                              from '@mui/material/Button'
import Box                                 from '@mui/material/Box'
import Typography                          from '@mui/material/Typography'
import Container                           from '@mui/material/Container'
import { useApi }                          from '../utils/api'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Stack }                           from '@mui/material'
import { useContext, useState }            from 'react'
import { ParkingAppContext }               from '../App'
import Iconify                             from '../ui/components/Iconify'
import { useTranslation }                  from 'react-i18next'
import Page                                from '../ui/components/Page'
import { fDateTime, fPrice }               from '../utils/textutils'
import BaseTable                           from '../ui/components/base-table'
import Label                               from '../ui/components/Label'
import PrintTicketWidget                   from '../ticket/PrintTicketWidget'

export default function CustomerTicketsPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const { phone, setPhone } = useContext(ParkingAppContext)
  const navigate = useNavigate()

  const columns = [
    { field: 'created', headerName: t('Created'), width: 155, sortable: false, filterable: false, renderCell: (cell) => fDateTime(cell.row.created) },
    { field: 'licence', headerName: t('Licence'), width: 90, sortable: false, filterable: false },
    { field: 'city', headerName: t('City'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => cell.row.city?.name },
    { field: 'zone', headerName: t('Zone'), width: 50, sortable: false, filterable: false, renderCell: (cell) => cell.row.zone?.keyword },
    { field: 'validFrom', headerName: t('Valid from'), width: 155, sortable: false, filterable: false, renderCell: (cell) => fDateTime(cell.row.validFrom) },
    { field: 'validTo', headerName: t('Valid to'), width: 155, sortable: false, filterable: false, renderCell: (cell) => fDateTime(cell.row.validTo) },
    { field: 'units', headerName: t('Hours'), width: 70, sortable: false, filterable: false, },
    { field: 'price', headerName: t('Price'), width: 70, sortable: false, filterable: false, renderCell: (cell) => fPrice(cell.row.price) },
    { field: 'id', headerName: t('Actions'), width: 120, sortable: false, filterable: false, renderCell: (cell) => <PrintTicketWidget ticket={cell.row} /> },
  ];

  return (<Page title={t('SMS Parking tickets')}>
    <Container>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h2" gutterBottom sx={{ fontSize: { xs: '1.2em', sm: '1.5em', md: '2em' } }}>{t('SMS Parking tickets')}</Typography>
        <Box flexGrow={'1'} />
        <Label startIcon={<Iconify icon={'material-symbols:account-circle'} />}>{phone?.split(':')[0]}</Label>
        <Button variant="outlined" onClick={() => {
          setPhone(null)
          navigate('/')
        }}>{t('Logout')}</Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.customer.list(page, pageSize)
          } catch (e) {
            console.log('Failed to load users', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)
}