// component
import Iconify    from '../../components/Iconify';
import * as React from 'react'
import { Trans }  from 'react-i18next'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    key: 'home',
    title: <Trans i18nKey={'Home'} />,
    path: '/home',
    icon: getIcon('material-symbols:dashboard'),
  },
  {
    key: 'statistics/tickets',
    title: <Trans i18nKey={'Ticket statistics'} />,
    path: '/statistics/tickets',
    icon: getIcon('material-symbols:bar-chart'),
  },
  {
    key: 'statistics/messages',
    title: <Trans i18nKey={'Message statistics'} />,
    path: '/statistics/messages',
    icon: getIcon('material-symbols:bar-chart'),
  },
  {
    key: 'tickets',
    title: <Trans i18nKey={'Tickets'} />,
    path: '/tickets',
    icon: getIcon('material-symbols:groups-rounded'),
  },
  {
    key: 'messages',
    title: <Trans i18nKey={'Messages'} />,
    path: '/messages',
    icon: getIcon('material-symbols:sms'),
  },
  {
    key: 'cities',
    title: <Trans i18nKey={'Cities'} />,
    path: '/cities',
    icon: getIcon('material-symbols:location-city'),
  },
  {
    key: 'divider-0',
    divider: true,
  },
  {
    key: 'users',
    title: <Trans i18nKey={'Users'} />,
    path: '/users',
    icon: getIcon('material-symbols:groups-rounded'),
  },
  {
    key: 'app-access',
    title: <Trans i18nKey={'App accesses'} />,
    path: '/app-accesses',
    icon: getIcon('material-symbols:shield-lock'),
  },
];

export default navConfig;
