import { useState } from 'react'
import { Buffer }   from 'buffer'

const SESSION_KEY_USER = 'sk.mediatex.parking.user'
const SESSION_KEY_PHONE = 'sk.mediatex.parking.phone'

const getLocalStorageUser = () => {
  let userJson = localStorage.getItem(SESSION_KEY_USER) ?? null
  return userJson === null ? null : JSON.parse(userJson)
}

let currentUser = getLocalStorageUser()

const setLocalStorageUser = (user) => {
  if (user === null) {
    localStorage.removeItem(SESSION_KEY_USER)
  } else {
    localStorage.setItem(SESSION_KEY_USER, JSON.stringify(user))
  }
}

export const useCurrentUser = (): [Object, Function] => {

  const [user, setUser] = useState(currentUser)

  const setUserInternal = (usr) => {
    currentUser = usr
    setUser(usr)
    setLocalStorageUser(usr)
  }

  // return global userState state and setter function
  return [user, setUserInternal];
}

const getSessionStoragePhone = () => {
  let phoneAuth = localStorage.getItem(SESSION_KEY_PHONE) ?? null
  return phoneAuth === null ? null : phoneAuth
}

let currentPhone = getSessionStoragePhone()

const setSessionStoragePhone = (phone) => {
  if (phone === null) {
    localStorage.removeItem(SESSION_KEY_PHONE)
  } else {
    localStorage.setItem(SESSION_KEY_PHONE, phone)
  }
}

export const useCurrentPhone = (): [String, Function] => {

  const [phone, setPhone] = useState(currentPhone)

  const setPhoneInternal = (tel) => {
    currentPhone = tel
    setPhone(tel)
    setSessionStoragePhone(tel)
  }

  // return global userState state and setter function
  return [phone, setPhoneInternal];
}

export function useApi() {

  const BASE_URL = process.env.REACT_APP_API_URL ?? `https://${window.location.host}/parking-api`

  const [user, setCurrentUser] = useCurrentUser()
  const [phoneAuthToken, setCurrentPhone] = useCurrentPhone()

  const send = (url, options) => {
    return new Promise((resolve, reject) => {
      fetch(url, options)
        .then(async (response) => {
          let data = null
          if (response.headers.get('Content-Type').includes('application/json')) {
            try {
              data = JSON.parse(await response.text())
            } catch (e) {
              data = null
            }
          } else if (response.headers.get('Content-Type').includes('application/pdf')) {
            data = await response.blob()
          }
          if (response.status / 100 !== 2) {
            if (response.status === 401) {
              setCurrentUser(null)
              setCurrentPhone(null)
              window.location.reload()
            }
            reject({
              ...data,
              status: response.status,
              statusText: response.statusText,
            })
          } else {
            resolve(data)
          }
        })
        .catch(error => {
          console.error(`Api failed on ${options.method.toUpperCase()} ${url}`, error)
          reject(error)
        })
    })
  }

  const encodeURIComponentOwn = (component) => {
    return Array.isArray(component) ? component.map(it => encodeURIComponent(it)).join(',') : encodeURIComponent(component)
  }

  const get = (path, args) => {
    let query = ''
    if (args && Object.getOwnPropertyNames(args).length > 0) {
      query = '?' + (Object.getOwnPropertyNames(args).filter(key => typeof args[key] !== 'undefined' && args[key] !== null).map(key => `${key}=${encodeURIComponentOwn(args[key])}`).join('&'))
    }
    let headers = {}
    if (!!user) {
      headers.Authorization = 'Bearer ' + user.accessToken
    } else if (phoneAuthToken !== null) {
      headers.Authorization = 'Basic ' + Buffer.from(phoneAuthToken).toString('base64')
    }
    return send(BASE_URL+path+query, { method: "get", headers: headers, })
  }

  const put = (path, id, data) => {
    let headers = {
      'Content-Type': 'application/json'
    }
    if (user !== null) {
      headers.Authorization = 'Bearer ' + user.accessToken
    }
    return send(BASE_URL+path+`/${id}`, { method: "put", body: JSON.stringify(data), headers: headers, })
  }

  const post = (path, data, headers = {}) => {
    let reqHeaders = {
      'Content-Type': 'application/json',
      ...headers,
    }
    if (user !== null) {
      reqHeaders.Authorization = 'Bearer ' + user.accessToken
    } else if (phoneAuthToken !== null) {
      reqHeaders.Authorization = 'Basic ' + Buffer.from(phoneAuthToken).toString('base64')
    }
    return send(BASE_URL+path, { method: "post", body: JSON.stringify(data), headers: reqHeaders, })
  }

  const _delete = (path) => {
    let headers = { }
    if (!!user) {
      headers.Authorization = 'Bearer ' + user.accessToken
    }
    return send(BASE_URL+path, { method: "delete", headers: headers, })
  }

  return {
    appAccess: {
      detail: (id) => get(`/app-access/${id}`),
      save: (data) => data.id ? put(`/app-access`, data.id, data) : post(`/app-access`, data),
      list: (query, page, count) => get('/app-access/list', { query: query, page: page, count: count }),
      delete: (id) => _delete(`/app-access/${id}`)
    },
    user: {
      validateHash: () => get('/user/validateHash', {}),
      login: (email, password) => post('/user/login', { email: email, password: password }),
      logout: () => _delete('/user/logout'),
      detail: (id) => get(`/user/${id}`),
      save: (data) => data.id ? put(`/user`, data.id, data) : post(`/user`, data),
      list: (query, types, page, count) => get('/user/list', { query: query, types: types.join(','), page: page, count: count }),
      delete: (id) => _delete(`/user/${id}`)
    },
    city: {
      detail: (id) => get(`/city/${id}`),
      save: (data) => data.id ? put(`/city`, data.id, data) : post(`/city`, data),
      delete: (id) => _delete(`/city/${id}`),
      list: (query, page, count) => get('/city/list', { query: query, page: page, count: count }),
    },
    zone: {
      detail: (id) => get(`/zone/${id}`),
      save: (data) => data.id ? put(`/zone`, data.id, data) : post(`/zone`, data),
      scheduleChange: (data) => put(`/zone/scheduleChange`, data.id, data),
      delete: (id) => _delete(`/zone/${id}`),
      list: (cityId, query, page, count) => get(`/zone/list/${cityId}`, { query: query, page: page, count: count }),
      scheduledChangesList: (zoneId) => get(`/zone/scheduledChanges/list/${zoneId}`),
      deleteZoneChange: (zoneChangeId) => _delete(`/zone/deleteChange/${zoneChangeId}`),
    },
    message: {
      list: (updatedAfterMillis, dateFrom, dateTo, query, page, count) => get(`/message/list`, { updatedAfterMillis: updatedAfterMillis,
        query: query, dateFrom: dateFrom, dateTo: dateTo,
        page: page, count: count,
      }),
      detail: (id) => get(`/message/${id}`),
      statistics: (from, to, cityIds, groupBy) => get(`/message/statistics` + (from && to ? `/${from}-${to}` : '') + (cityIds ? `/${cityIds.join(',')}` : ''), {
        groupBy: groupBy?.join(',')
      }),
    },
    ticket: {
      list: (updatedAfterMillis, query, dateFrom, dateTo, cityIds, zoneIds, page, count) => get(`/ticket/list`, {
        updatedAfterMillis: updatedAfterMillis, dateFrom: dateFrom, dateTo: dateTo,
        cityIds: cityIds?.join(',') ?? null,
        zoneIds: zoneIds?.join(',') ?? null,
        query: query, page: page, count: count,
      }),
      detail: (id) => get(`/ticket/${id}`),
      statistics: (from, to, cityIds, groupBy) => get(`/ticket/statistics` + (from && to ? `/${from}-${to}` : '') + (cityIds ? `/${cityIds.join(',')}` : ''), {
        groupBy: groupBy?.join(',')
      }),
    },
    log: {
      list: (ticketId, messageId) => get(`/log/list`, {
        ticketId: ticketId,
        messageId: messageId,
      }),
    },
    customer: {
      list: (page, pageSize) => get(`/customer/tickets`, { page: page, count: pageSize }),
      login: (tel, code) => post(`/customer`, { phone: tel, code: code }),
      download: (id) => get(`/customer/ticket/${id}.pdf`)
    },
  }
}