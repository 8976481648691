import { FormControl, Input, InputLabel } from '@mui/material'
import PropTypes                          from 'prop-types'
import React                              from 'react'

const ShortFormControl = ({name, title, handleChange, value, inputProps}) => {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel htmlFor={`control-${name}`}>{title}</InputLabel>
      <Input
        id={`control-${name}`}
        value={value}
        onChange={handleChange(name)}
        {...inputProps}
        inputProps={inputProps}
      />
    </FormControl>
  )
}

ShortFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
}

export default ShortFormControl