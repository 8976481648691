import { Trans, useTranslation } from 'react-i18next'
import {
  Avatar,
  Container, Divider,
  Grid, Link,
  List,
  ListItem,
  ListItemAvatar, ListItemButton,
  ListItemText, Stack, Typography,
} from '@mui/material'
import { useApi }                        from '../utils/api'
import React, { useEffect, useState }    from 'react'
import { fDateTime, fPrice }             from '../utils/textutils'
import Page                              from '../ui/components/Page'
import { BaseBreadcrumbs }               from '../ui/components/BaseBreadcrumbs'
import { useParams, Link as RouterLink } from 'react-router-dom'
import Iconify                           from '../ui/components/Iconify'
import PrintTicketWidget                 from './PrintTicketWidget'
import BaseTable                         from '../ui/components/base-table'

export default function TicketPage () {

  const { ticketId } = useParams()
  const { t } = useTranslation()
  const api = useApi()
  const [ticket, setTicket] = useState(null)

  useEffect(() => {
    api.ticket.detail(ticketId).then(msg => {
      setTicket(msg)
    }).catch(e => {})
  }, [ticketId])

  const getReminderIcon = () => {
    if (!ticket?.reminderAt) {
      return null
    }
    const reminderSent = !!ticket?.reminderSentAt
    const reminderIcon = reminderSent ? 'material-symbols:check-circle' : 'material-symbols:schedule'
    const iconColor = reminderSent ? 'success.dark' : 'warning.dark'
    return <Iconify icon={reminderIcon} sx={{ color: iconColor, width: 24, height: 24 }} />
  }
  const getSyncLogIcon = (state) => {
    const success = state === 'synced'
    const reminderIcon = success ? 'material-symbols:check-circle' : 'material-symbols:error'
    const iconColor = success ? 'success.dark' : 'warning.dark'
    return <Iconify icon={reminderIcon} sx={{ color: iconColor, width: 24, height: 24 }} />
  }

  const logColumns = [
    { field: 'messageId', headerName: t('Message ID'), width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        return cell.row.messageId && <Link
          component={RouterLink}
          variant="body2"
          to={`/messages/${cell.row.messageId}`}
        >
          {cell.row.messageId}
        </Link>
    }},
    { field: 'created', headerName: t('Created'), width: 140, sortable: false, filterable: false, renderCell: (cell) => fDateTime(cell.row.created) },
    { field: 'action', headerName: t('Log action'), flex: 1, sortable: false, filterable: false },
    { field: 'message', headerName: t('Log message'), flex: 2, sortable: false, filterable: false },
  ];

  return (<Page title={`#${ticketId} ${t('Ticket')}`}>
    <Container maxWidth={'xl'}>
      <BaseBreadcrumbs key={'breadcrumb-messages'}
                       title={`${t('Ticket')} #${ticketId}`}
                       items={[
                         { href: '/tickets', title: t('Tickets') }
                       ]}/>
      <Stack direction={'row'} sx={{ py: 2 }}>
        {ticket && <PrintTicketWidget ticket={ticket} />}
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <DataRow title={t('City')} content={ticket?.city?.name} icon={'material-symbols:location-city'}/>
            <Divider component={'li'} />
            <DataRow title={t('Zone')} content={ticket?.zone?.name} icon={'material-symbols:location-city'}/>
            <Divider component={'li'} />
            <DataRow title={t('Licence')} content={ticket?.licence} icon={'material-symbols:license'}/>
            <Divider component={'li'} />
            <DataRow title={t('Valid from')} content={fDateTime(ticket?.validFrom)} icon={'material-symbols:timer'} />
            <Divider component={'li'} />
            <DataRow title={t('Valid to')} content={fDateTime(ticket?.validTo)} icon={'material-symbols:timer-off'} />
            <Divider component={'li'} />
            <DataRow title={`${t('PriceWithVat')} / ${t('Vat')} / ${t('PriceNoVat')}`} content={`${fPrice(ticket?.priceWithVat)} / ${fPrice(ticket?.vat)} / ${fPrice(ticket?.price)}`} icon={'material-symbols:euro'} />
            <Divider component={'li'} />
            <DataRow title={t('Units')} content={ticket?.units} icon={'material-symbols:account-balance-wallet'} />
            {ticket?.message && <>
              <Divider component={'li'} />
              <DataRow title={t('Phone number')} content={ticket?.message.telFrom} icon={'material-symbols:phone-enabled'}/>
            </>}
            {ticket?.ticketMessage && <>
              <Divider component={'li'} />
              <ClickableDataRow
                icon={'material-symbols:sms'}
                title={t('Text of ticket SMS')}
                content={ticket.ticketMessage.content}
                href={`/messages/${ticket.ticketMessage.id}`}
              />
            </>}
            {ticket?.message && <>
              <Divider component={'li'} />
              <ClickableDataRow
                icon={'material-symbols:sms'}
                title={t('Text of incoming SMS')}
                content={ticket.message.content}
                href={`/messages/${ticket.message.id}`}
              />
            </>}
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <DataRow title={t('Created')} content={ticket?.created ? fDateTime(ticket.created) : t('Not set')} />
            <Divider component={'li'} />
            <DataRow title={t('Modified')} content={ticket?.modified ? fDateTime(ticket.modified) : t('Not set')} />
            <Divider component={'li'} />
            <DataRow title={t('Reminder at')} content={ticket?.reminderAt ? fDateTime(ticket.reminderAt) : t('Not set')}
                     secondaryAction={getReminderIcon()}/>
            {ticket?.reminderAt && <>
              <Divider component={'li'} />
              <DataRow title={t('Reminder sent at')} content={ticket?.reminderSentAt ? fDateTime(ticket.reminderSentAt) : t('Not set')} />
            </>}
            {ticket?.invoiceId && <>
              <Divider component={'li'} />
              <DataRow title={<Trans i18nKey={'Invoice no.'} values={{ no: ticket.invoiceId }} />}
                       content={fDateTime(ticket.created)} />
            </>}
            {ticket?.syncLogs && ticket.syncLogs.map((syncLog) => (
              <div key={`sync-${syncLog.id}`}>
                <Divider key={`synclog-divider-${syncLog.id}`} component={'li'} />
                <DataRow key={`synclog-${syncLog.id}`}
                         title={`${t('Sync with 3th party')}: ${syncLog.connectionName}`}
                         content={fDateTime(syncLog.created)}
                         icon={'material-symbols:sync-outline'}
                         secondaryAction={getSyncLogIcon(syncLog.state)} />
              </div>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h4'}>{t('Logs')}</Typography>
          <BaseTable
            columns={logColumns}
            loadCallback={async (query, page, pageSize) => {
              try {
                return await api.log.list(ticketId)
              } catch (e) {
                console.log('Failed to load ticket logs', e)
                return []
              }
            }}
          />
        </Grid>
      </Grid>
    </Container>
  </Page>)
}

function DataRow({title, content, icon, secondaryAction}) {
  return (<ListItem secondaryAction={secondaryAction}>
    {icon && <ListItemAvatar>
      <Avatar>
        <Iconify icon={icon} />
      </Avatar>
    </ListItemAvatar>}
    <ListItemText primary={content} secondary={title} />
  </ListItem>)
}
function ClickableDataRow({title, content, href, icon}) {
  return (<ListItem>
    <ListItemButton sx={{ p: 0 }} component={RouterLink} to={href}>
      {icon && <ListItemAvatar>
        <Avatar>
          <Iconify icon={icon} />
        </Avatar>
      </ListItemAvatar>}
      <ListItemText primary={content} secondary={title} />
    </ListItemButton>
  </ListItem>)
}
